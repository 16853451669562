import { useState } from "react";
import { Link, useLoaderData } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase/config";
import Modal from "../components/Modal";
import { SingleCarproposal } from "../components/SingleCarProposal";
import { SingleCartestdrive } from "../components/SingleCarTestdrive";

export default function CarDetails() {
  const car = useLoaderData();

  document.title = "FORD " + car.model + " " + car.id;

  let imageSource =
    "/images/" +
    encodeURIComponent(
      car.model
        .toLowerCase()
        .replaceAll(/\s/g, "")
        .replaceAll(/-/g, "")
        .replaceAll(/\//g, "")
    ) +
    "-" +
    encodeURIComponent(
      car.body
        .toLowerCase()
        .replaceAll(/\s/g, "")
        .replaceAll(/-/g, "")
        .replaceAll(/\//g, "")
    ) +
    "-" +
    encodeURIComponent(
      car.trim
        .toLowerCase()
        .replaceAll(/\s/g, "")
        .replaceAll(/-/g, "")
        .replaceAll(/\//g, "")
    ) +
    "-" +
    encodeURIComponent(
      car.color
        .toLowerCase()
        .replaceAll(/\s/g, "")
        .replaceAll(/-/g, "")
        .replaceAll(/\//g, "")
    ) +
    ".jpeg";

  console.log(imageSource); // Проверка на новия URL

  //   let imageSource = encodeURIComponent(
  //     `../images/${car.model
  //       .toLowerCase()
  //       .replaceAll(/\s/g, "")
  //       .replaceAll(/-/g, "")
  //       .replaceAll(/\//g, "")}-${car.body
  //       .toLowerCase()
  //       .replaceAll(/\s/g, "")
  //       .replaceAll(/-/g, "")
  //       .replaceAll(/\//g, "")}-${car.trim
  //       .toLowerCase()
  //       .replaceAll(/\s/g, "")
  //       .replaceAll(/-/g, "")
  //       .replaceAll(/\//g, "")}-${car.color
  //       .toLowerCase()
  //       .replaceAll(/\s/g, "")}.jpeg`
  //   );
  //   console.log(imageSource);

  let discount = ((car.old_price - car.new_price) / car.old_price) * 100;

  const [showModal, setShowModal] = useState(false);
  const [proposal, setProposal] = useState(false);
  const [testdrive, setTestdrive] = useState(false);
  const handleClose = () => {
    setShowModal(false);
    setProposal(false);
    setTestdrive(false);
  };
  const requestProposal = () => {
    setShowModal(true);
    setProposal(true);
    window.scrollTo(0, 0);
  };
  const requestTestdrive = () => {
    setShowModal(true);
    setTestdrive(true);
    window.scrollTo(0, 0);
  };

  const placeholderImage = "/images/default-car.jpg";
  const onImageError = (e) => {
    e.target.src = placeholderImage;
    console.log("Грешка при зареждане на изображението: ", e.target.src);
  };

  // Логика за бутона Всичко за модела
  let href;

  switch (car.model.toLowerCase()) {
    case "courier":
      href = "https://sale.ford.bg/new-transit-courier";
      break;
    case "connect":
      href = "https://sale.ford.bg/transit-connect";
      break;
    case "puma":
      href = "https://ford.bg/cars/new-puma";
      break;
    case "focus":
      href = "https://ford.bg/cars/focus";
      break;
    case "new kuga":
      href = "https://ford.bg/cars/kuga-2024";
      break;
    case "new custom":
      href = "https://sale.ford.bg/transit-custom";
      break;
    case "tourneo connect":
      href = "https://ford.bg/cars/tourneo-connect";
      break;
    case "transit":
      href = "https://ford.bg/commercial_cars/transit";
      break;
    case "kuga":
      href = "https://ford.bg/cars/kuga";
      break;
    case "ranger":
      href = "https://sale.ford.bg/ranger-raptor";
      break;
    case "mach-e":
      href = "https://ford.bg/cars/mustang-mach-e";
      break;
    case "mustang":
      href = "https://ford.bg/cars/new-mustang";
      break;
    case "bronco":
      href = "https://sale.ford.bg/bronco";
      break;
    case "explorer":
      href = "https://ford.bg/cars/explorer";
      break;
    default:
      href = "https://ford.bg/cars/" + car.model.toLowerCase();
  }

  return (
    <div className="p-8">
      <div>
        <div className="flex justify-between">
          <div className="flex flex-col">
            <p className="text-sm">
              <Link to="/">&#x3c;&#x3c; Обратно към всички оферти</Link>
            </p>
            <h1 className="mt-8 text-4xl uppercase text-fordBlue">
              FORD &nbsp; {car ? car.model : `No such car`}
            </h1>
            <p className="text-sm">Оферта {car.id.toUpperCase()}</p>
          </div>
          {discount !== 0 && (
            <div className="flex flex-col px-8 py-4 mt-auto text-center rounded-sm bg-fordLight">
              <p className="tracking-widest text-white uppercase">
                Специална оферта
              </p>
              <b className="tracking-widest text-center text-white uppercase">
                -
                {new Intl.NumberFormat("bg", {
                  maximumFractionDigits: 0,
                }).format(discount)}
                %
              </b>
              {car.model === "CONNECT" && (
                <p className="text-center text-white">
                  СПЕЦИАЛНАТА ОФЕРТА ВАЖИ ПРИ
                  <br />
                  ПОКУПКА ПРЕЗ МОТО-ПФОЕ ЛИЗИНГ
                </p>
              )}
              {car.model !== "CONNECT" && (
                <p className="text-center text-white">
                  ДОПЪЛНИТЕЛЕН БОНУС ПРИ
                  <br />
                  ПОКУПКА ПРЕЗ МОТО-ПФОЕ ЛИЗИНГ
                </p>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="grid grid-cols-1 mt-16 lg:grid-cols-3">
        <div className="flex flex-col justify-between col-span-1">
          <img src={imageSource} alt="" onError={onImageError} />
          <p className="text-xs text-gray-400">
            Изображението е с илюстративен характер. За да потвърдим наличността
            на избрания от Вас автомобил и Ви изпратим обвързваща оферта, моля,
            използвайте формата за запитване тук или се свържете с най-близкото
            представителство на Ford в България.
          </p>
        </div>
        <div className="self-center h-full col-span-1 px-6 border-l border-r">
          <div>
            <div className="flex justify-between p-2 py-4 border-b">
              <p className="text-gray-500">Гориво</p>
              <p className="uppercase text-fordBlue">{car.fuel}</p>
            </div>
            <div className="flex justify-between p-2 py-4 border-b bg-gray-50">
              <p className="text-gray-500">Двигател</p>
              <p className="uppercase text-fordBlue">{car.engine}</p>
            </div>
            <div className="flex justify-between p-2 py-4 border-b">
              <p className="text-gray-500">Каросерия</p>
              <p className="uppercase text-fordBlue">{car.body}</p>
            </div>
            <div className="flex justify-between p-2 py-4 border-b bg-gray-50">
              <p className="text-gray-500">Цвят</p>
              <p className="uppercase text-fordBlue">{car.color}</p>
            </div>
            <div className="flex justify-between p-2 py-4 border-b">
              <p className="text-gray-500">Задвижване</p>
              <p className="uppercase text-fordBlue">{car.drivetrain}</p>
            </div>
            <div className="flex justify-between p-2 py-4 border-b bg-gray-50">
              <p className="text-gray-500">Скоростна кутия</p>
              <p className="uppercase text-fordBlue">{car.gearbox}</p>
            </div>
            <div className="flex justify-between p-2 py-4 border-b">
              <p className="text-gray-500">Брой предавки</p>
              <p className="uppercase text-fordBlue">{car.gears}</p>
            </div>
            <div className="flex justify-between p-2 py-4 border-b bg-gray-50">
              <p className="text-gray-500">Оборудване</p>
              <p className="uppercase text-fordBlue">{car.trim}</p>
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-between col-span-1 pl-4">
          <div className="pt-8">
            {discount !== 0 && (
              <>
                <p className="text-end">Стара цена:</p>
                <p className="line-through text-end">
                  {new Intl.NumberFormat("bg", {
                    minimumFractionDigits: 0,
                  }).format(car.old_price)}{" "}
                  лв.
                </p>
              </>
            )}
            {discount !== 0 ? (
              <p className="text-end">Нова цена:</p>
            ) : (
              <p className="text-end">Цена:</p>
            )}
            <p className="mt-2 text-4xl text-end text-fordBlue">
              {new Intl.NumberFormat("bg", { minimumFractionDigits: 0 }).format(
                car.new_price
              )}
            </p>
            <p className="text-sm text-end">лв. с ДДС</p>
            {discount !== 0 && (
              <p className="text-xl text-fordBlue text-end">
                Спестявате{" "}
                {new Intl.NumberFormat("bg", {
                  minimumFractionDigits: 0,
                }).format(car.old_price - car.new_price)}{" "}
                лв.
              </p>
            )}
          </div>

          <div className="pl-4 mt-4">
            <button
              className="w-full py-3 mt-8 btn-primary"
              onClick={requestProposal}
            >
              Попитайте за специалната ни оферта
            </button>
            <div className="flex flex-col justify-between gap-2 lg:flex-row">
              <button
                className="mt-4 btn-secondary grow"
                onClick={requestTestdrive}
              >
                Заявете тестдрайв
              </button>
              <a
                href={href}
                target="_blank"
                rel="noreferrer"
                className="mr-2 grow"
              >
                <button className="w-full mt-4 ml-2 btn-secondary">
                  Всичко за модела
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
      {showModal && proposal && (
        <Modal handleClose={handleClose}>
          <h2 className="mt-8 text-xl text-center uppercase text-fordBlue">
            Попитайте за специалната ни оферта за автомобил Ford {car.model}{" "}
            {car.id}
          </h2>
          <SingleCarproposal model={car.model} ofertanomer={car.id} />
        </Modal>
      )}
      {showModal && testdrive && (
        <Modal handleClose={handleClose}>
          <h2 className="mt-8 text-xl text-center uppercase text-fordBlue">
            Попитайте за тестдрайв на автомобил Ford {car.model}
          </h2>
          <SingleCartestdrive model={car.model} ofertanomer={car.id} />
        </Modal>
      )}
    </div>
  );
}

export const carDetailsLoader = async ({ params }) => {
  const { id } = params;
  const docRef = doc(db, "cars", id);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    return null;
  }
};
